import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "src/redux/store";

export interface GlobalState {
  
  username: string;
  name: string;
  createdAt: string;
  avatar: string;
  userId: string;
  plan: string;
  period: string; // Monthly or Yearly
  trialEnd: string;
  showMessagePopup: boolean;
  userToken: string;
  userInviteToken: string;
  listingId: string;
  isFirstTime: boolean;
  isFirstTimeListingsReady: boolean;
  pms: string;
  perUserPrompts: string;
  neverEscalateDraftMode: boolean;
  offerLanguage: string;
  summaryLanguage: string;
  autopilotSignature: string | null; // will be the sign off of the user
  autoEnableListings: boolean;
  extensionInstalledAt: string | null;
  webAppCredsIsExpired: boolean | null;
  templateFirstName: string;
  numListings: number;
  widgetEligible : boolean;
  widgetEnabled : boolean;
}

const initialState: GlobalState = {
  username: "",
  name: "",
  createdAt: "",
  avatar: "",
  userId: "",
  plan: "",
  period: "",
  trialEnd: "",
  showMessagePopup: true,
  userToken: "",
  userInviteToken: "",
  listingId: "",
  isFirstTime: false,
  isFirstTimeListingsReady: false,
  pms: "",
  perUserPrompts: "",
  neverEscalateDraftMode: true,
  offerLanguage: "en",
  summaryLanguage: "en",
  autopilotSignature: null,
  autoEnableListings: true,
  extensionInstalledAt: null,
  webAppCredsIsExpired: false,
  templateFirstName: "",
  numListings: 0,
  widgetEligible : false,
  widgetEnabled : false,
};

const globalSlice = createSlice({
  name: "global",
  initialState,
  reducers: {
    setUserInfo: (state, action) => {
      state.username = action.payload.username;
      state.name = action.payload.name;
      state.createdAt = action.payload.createdAt;
      state.avatar = action.payload.avatar;
      state.userId = action.payload.userId;
      state.plan = action.payload.plan;
      state.period = action.payload.period;
      state.trialEnd = action.payload.trialEnd;
      state.showMessagePopup = action.payload.showMessagePopup;
      state.userToken = action.payload.userToken;
      state.userInviteToken = action.payload.userInviteToken;
      state.listingId = action.payload.listingId;
      state.isFirstTime = action.payload.isFirstTime;
      state.pms = action.payload.pms;
      state.isFirstTimeListingsReady = action.payload.isFirstTimeListingsReady;
      state.perUserPrompts = action.payload.perUserPrompts;
      state.neverEscalateDraftMode = action.payload.neverEscalateDraftMode;
      state.offerLanguage = action.payload.offerLanguage;
      state.summaryLanguage = action.payload.summaryLanguage;
      state.autopilotSignature = action.payload.autopilotSignature;
      state.autoEnableListings = action.payload.autoEnableListings;
      state.extensionInstalledAt = action.payload.extensionInstalledAt;
      state.webAppCredsIsExpired = action.payload.webAppCredsIsExpired;
      state.templateFirstName = action.payload.templateFirstName;
      state.numListings = action.payload.numListings;
      state.widgetEligible = action.payload.widgetEligible;
      state.widgetEnabled = action.payload.widgetEnabled;
    },
    setUserToken: (state, action) => {
      state.userToken = action.payload;
    },
  },
});

export const { setUserInfo, setUserToken } = globalSlice.actions;

export const getUserInfo = (state: RootState) => {
  // console.log('getUserInfo state:', state.global);
  return state.global;
};

export default globalSlice.reducer;